@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import url('./base.css');



/* ---------------- GENERAL ------------- */

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

html {
  font-size: 0.85rem;
}

body {
  height: auto;
  font-family: poppins, sans-serif;
  font-size: 0.88rem;
  background-color: var(--color-background);
  color: var(--color-black);
}

img {
  display: block;
  width: 100%;
}

a {
  color: var(--color-black);
}

.tr-bg-color {
  background-color: var(--color-light);
  color: var(--color-primary);
}

h1 {
  font-size: 1.6rem;
  font-weight: 500;
}

h2 i {
  font-size: 1.2rem;
}

h2 {
  font-size: 1.4rem;
  font-weight: 420;
  color: var(--color-primary);
}

h3 {
  font-size: 0.87rem;
  font-weight: normal;
}

small {
  font-size: 0.75rem;
}

.center {
  text-align: center;
}

.left-position {
  text-align: right;
}

.font-weight-6 {
  font-weight: 700;
}


.loader {
  height: 4rem;
  width: 4rem;
  margin: auto;
}

.hr-title {
  position: relative;
  left: 67%;
  width: max-content;
  border-radius: var(--border-raduis);
  background-color: var(--color-primary);
  padding: 0 20px;
  color: var(--color-white);
  font-size: 11px;
  text-transform: uppercase;
}

.fw {
  font-weight: 520;
  color: var(--color-black);
}

.photo {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.photo .left {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.photo .left i {
  font-size: 0.8rem;
  margin-right: 0.1rem;
  color: var(--color-primary);
}

.photo .photo-profil {
  width: 10rem;
  height: 10rem;
  background-color: transparent;
  border: 1px solid var(--color-gray);
  border-radius: 7px;
  overflow: hidden;
}

.photo-profil img {
  object-fit: fill;
  height: 10rem;
  width: 10rem;
}


.btn-cicle-min {
  padding: 0.5rem 1rem;
  background-color: transparent;
  font-size: medium;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 7px;
  font-weight: 700;
  cursor: pointer;
  transition: var(--transition);
  -webkit-transition: var(-- transition);
}

.btn-cicle-min:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.btn-text{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  font-size: 2;
  cursor: pointer;
  font-weight: bold;
}

.btn-text_primary{
  color: var(--color-primary);
}
.btn-text_success{
  color: var(--color-success);
}
.btn-text_orange{
  color: var(--color-orange);
}

.container {
  margin: auto;
  width: 80%;
  min-height: 100vh;
  padding: 3rem 10rem;
}

.container-dashboard {
  width: 96%;
  margin: 0 auto;
}

.card-input {
  background-color: transparent;
  width: 100%;
  margin: 0 0 20px;
  padding: 0.5rem 1rem;
  border-radius: var(--border-raduis);
  border: var(--border);
  line-height: 1.3rem;
}

.flex {
  display: flex;
  justify-content: space-evenly;
}

.grid-custom-2 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
}
.grid-custom-4 {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr .3fr;
  gap: 1rem;
}
.grid-custom-5 {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr .3fr;
  gap: 1rem;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.grid-3 {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.grid-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-1 {
  margin-right: 1rem;
}
.mr-05 {
  margin-right: .5rem;
}

.title {
  margin: 3rem 0;
}


.color-primary {
  color: var(--color-primary);
}

.mb-5 {
  margin-bottom: 5rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}


.my-0-5 {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.my-4 {
  margin: 4rem 0;
}

.my-5 {
  margin: 5rem 0;
}

.mt-10 {
  margin-top: 10rem;
}

.ml-05 {
  padding-left: .5rem;
}

.ml-1 {
  padding-left: 1rem;
}
.space-between {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  justify-content: right;
  align-items: right;
}

.center-td {
  text-align: center;
}

.bb-dashed {
  border-bottom: 2px solid  var(--color-danger);
}

.container-auth {
  max-height: auto;
  margin: 0 auto;
}

.container-auth h2 {
  font-size: 3em;
  font-weight: 400;
  line-height: 1.2;
  color: var(--color-white);
  margin-bottom: 20px;
}

.container-auth p {
  color: var(--color-black);
}

.wrapper>div {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  min-height: 500px;
  margin: 5em auto;
  background-color: var(--color-primary);
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.45) 0 25px 20px -20px;
}

.box__auth>div {
  position: relative;
  flex: 1 0 50%;
  padding: 60px;
}

.y-style .welcome>* {
  color: var(--color-white);
}

.y-style {
  position: relative;
  right: 10%;
  transform: translateX(-70%);
  transform: translate3d(0, 20%, 0);
  -webkit-transform: translate3d(0, 20%, 0);
  animation: fade-in 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  -webkit-animation: fade-in 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
  }
}

.box__auth .y-style {
  transform: translate3d(0, 0, 0);
}


@media screen and (min-width: 768px) {
  .wrapper>div {
    flex-direction: row;
    max-width: 800px;
  }
}

.content-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-form::before {
  content: "";
  position: absolute;
  width: 300%;
  height: 200%;
  background-color: var(--color-white);
  transform: translate3d(-27%, 21%, 0);
  -webkit-transform: translate3d(-27%, 21%, 0);
  animation: move 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  -webkit-animation: move 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes move {
  from {
    opacity: 0;
    transform: translate3d(50%, 60%, 0);
  }

  to {
    opacity: 1;
  }
}

@media screen and (min-width: 768px) {
  .content-form::before {
    width: 200%;
    transform: translate3d(-18%, -10%, 0);
    -webkit-transform: translate3d(-18%, -10%, 0);
  }

  @keyframes move {
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
    }
  }
}

.box-select {
  margin: 0.5rem 0 0;
}

input,
.card-select {
  font-family: inherit;
  width: 100%;
  outline: 0;
  margin: 0 0 20px;
  padding: 10px 15px;
  border-radius: 7px;
  border: 1px solid var(--color-gray);
}

input::placeholder {
  color: var(--color-gray);
}

.check {
  position: relative;
  padding-left: 20px;
  font-size: 12px;
  float: left;
}

.check input {
  position: absolute;
  left: 0;
  top: 4px;
}

.forgot {
  float: right;
  font-size: 12px;
}

.forgot a {
  cursor: pointer;
  color: var(--color-primary);
}

.forgot a:hover {
  border-bottom: 1px dotted;
}

.custom-button {
  background-color: var(--color-primary);
  color: var(--color-white);
  width: 100%;
  margin: 30px 0;
  border: 0;
  line-height: 42px;
  border-radius: 7px;
  font-weight: 700;
  cursor: pointer;
  transition: var(--transition-ease-out);
  -webkit-transition: var(--transition-ease-out);
}

.custom-button:hover {
  background-color: transparent;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

/* dashboard */

.profile-photo {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  overflow: hidden;
}

.profile-photo-table {
  margin: 0.5rem 0;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  overflow: hidden;
}

.profile-photo-table img {
  width: 2.8rem;
  height: 2.8rem;
  object-fit: fill;
}

aside {
  height: 100vh;
  width: 15rem;
  position: fixed;
}


.profile {
  margin-bottom: 30px;
  text-align: center;
}

.profile img {
  display: block;
  width: 10rem;
  height: 8rem;
  border-radius: 50%;
  margin: 0 auto;
}


.profile h3 {
  color: var(--color-primary);
}

.profile p {
  color: var(--color-black);
  font-size: 12px;
}


.logo-auth img {
  width: 15rem;
  height: 10rem;
}

aside .close {
  display: none;
}

/* ---------------- SIDEBAR ------------- */

aside .sidebar {
  display: flex;
  flex-direction: column;
  height: 86vh;
  position: relative;
}

aside h3 {
  font-weight: 500;
}

aside .sidebar a {
  display: flex;
  color: var(--color-primary);
  gap: 1rem;
  align-items: center;
  position: relative;
  height: 3.7rem;
  transition: var(--transition);
}

aside .sidebar a span {
  font-size: 1.1rem;
  transition: var(--transition);
}

aside .sidebar a:last-child {
  color: var(--color-danger);
  position: absolute;
  cursor: pointer;
  top: 25rem;
  width: 100%;
}

aside .sidebar a.active {
  background-color: var(--color-light);
  color: var(--color-primary);
  border-radius: var(--border-raduis);
}

aside .sidebar a.active::before {
  content: "";
  width: 4px;
  height: 100%;
  background-color: var(--color-primary);
}

aside .sidebar a:hover span {
  margin-left: 0.7rem;
}

/* ---------------- MAIN ------------- */


main {
  float: right;
  width: 80.1%;
  margin-top: 1.5rem;
}

.radio-group {
  align-items: center;
  display: flex;
  gap: 2rem;
}

.radio-group span {
  font-size: 1.1rem;
}

.radio-btn-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}


/* ---------------- table ------------- */

main .container-table {
  margin-top: 2rem;
}

main .container-table h2 {
  margin-bottom: 1rem;
}

main .container-table table {
  background-color: var(--color-white);
  width: 100%;
  border-radius: var(--card-border-radius);
  padding: 0.7rem 1.8rem 1.8rem;
  text-align: left;
  box-shadow: var(--box-shadow);
  transform: var(--transition);
}

main .container-table table:hover {
  box-shadow: none;
}

table thead th {
  height: 2.8rem;
  border-bottom: var(--border-dashed);
}

table tbody td {
  height: 2.8rem;
  border-bottom: var(--border-dashed);
}

main .container-table h3 {
  text-align: center;
  display: block;
  margin: 1rem auto;
  color: var(--color-primary);
}

/* ---------------- RIGHT ------------- */

.right {
  margin-top: 1.4rem;
}

.right .top {
  display: flex;
  justify-content: end;
  gap: 2rem;
}

.right .top button {
  display: none;
}

.right .theme-toggler {
  background-color: var(--color-white);
  color: var(--color-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.6rem;
  width: 4.6rem;
  cursor: pointer;
  border-radius: var(--border-radius-1);
}

.right .theme-toggler span {
  font-size: 1.2rem;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right .theme-toggler span.active {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: var(--border-radius-1);
}

.right .top .profile {
  display: flex;
  gap: 2rem;
  text-align: right;
}

/* ---------------- RECENT UPDATES ------------- */

.right .recent-updates {
  position: relative;
  margin-top: 1rem;
}

.right .recent-updates h2 {
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}

.right .recent-updates .updates {
  font-size: 0.8rem;
  background-color: var(--color-white);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
  transition: var(--transition);
}

.right .recent-updates .updates:hover {
  box-shadow: none;
}

.right .recent-updates .updates .update {
  display: grid;
  grid-template-columns: 2.6rem auto;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* ---------------- SALES ANALYTICS ------------- */

.right .sales-analytics {
  margin-top: 2rem;
}

.right .sales-analytics h2 {
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}

.right .sales-analytics .item {
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.7rem;
  padding: 1.4rem var(--card-padding);
  border-radius: var(--border-radius-2);
  box-shadow: var(--box-shadow);
  transition: var(--transition);
}

.right .sales-analytics .item:hover {
  box-shadow: none;
}

.right .sales-analytics .right {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 0;
  width: 100%;
}

.right .sales-analytics .item .icon {
  padding: 0.6rem;
  color: var(--color-white);
  border-radius: 50%;
  background-color: var(--color-primary);
  display: flex;
}

.right .sales-analytics .item.offline .icon {
  background-color: var(--color-danger);
}

.right .sales-analytics .item.customers .icon {
  background-color: var(--color-success);
}

.right .sales-analytics .add-product {
  background-color: transparent;
  border: 2px dashed var(--color-primary);
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.right .sales-analytics .add-product:hover {
  background-color: var(--color-primary);
  color: white;
}

.right .sales-analytics .add-product div {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.right .sales-analytics .add-product div h3 {
  font-weight: 600;
}

/* ---------------- MEDIA QUERIES FOR TABLETTE ------------- */

@media screen and (max-width: 1200px) {
  .container {
    width: 94%;
    grid-template-columns: 7rem auto 23rem;
  }

  aside .logo h2 {
    display: none;
  }

  aside .sidebar h3 {
    display: none;
  }

  aside .sidebar a {
    width: 5.6rem;
  }

  aside .sidebar a:last-child {
    position: relative;
    margin-top: 1.8rem;
  }

  main .insights {
    grid-template-columns: 1fr;
    gap: 0;
  }

  main .container-table {
    width: 94%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 2rem 0 0 8.8rem;
  }

  main .container-table table {
    width: 83vw;
  }

  main table tbody tr td:last-child,
  main table tbody tr td:first-child {
    display: none;
  }
}

/* ---------------- MEDIA QUERIES FOR TABLETTE ------------- */

@media screen and (max-width: 768px) {
  .container-dashboard {
    width: 100%;
    grid-template-columns: 1fr;
  }

  aside {
    position: fixed;
    left: -100%;
    background-color: var(--color-white);
    width: 18rem;
    z-index: 3;
    box-shadow: 1rem 3rem 4rem var(--color-light);
    height: 100vh;
    padding-right: var(--card-padding);
    display: none;
    animation: showMenu 300ms ease forwards;
  }

  @keyframes showMenu {
    to {
      left: 0;
    }
  }

  aside .logo {
    margin-left: 1rem;
  }

  aside .logo h2 {
    display: inline;
  }

  aside .sidebar h3 {
    display: inline;
  }

  aside .sidebar a {
    width: 100%;
    height: 3.4rem;
  }

  aside .sidebar a:last-child {
    position: absolute;
    bottom: 5rem;
  }

  aside .close {
    display: inline-block;
    cursor: pointer;
  }

  main {
    margin-top: 8rem;
    padding: 0 1rem;
  }

  main .container-table {
    position: relative;
    margin: 3rem 0 0 0;
    width: 100%;
  }

  main .container-table table {
    width: 100%;
    margin: 0;
  }

  .right {
    width: 94%;
    margin: 0 auto 4rem;
  }

  .right .top {
    position: fixed;
    left: 0;
    top: 0;
    align-items: center;
    padding: 0 0.8rem;
    height: 4.6rem;
    background-color: var(--color-white);
    width: 100%;
    margin: 0;
    z-index: 2;
    box-shadow: 0 1rem 1rem var(--color-light);
  }

  .right .top .theme-toggler {
    width: 4.4rem;
    position: absolute;
    left: 66%;
  }

  .right .top .info {
    display: none;
  }

  .right .top button {
    display: inline-block;
    background-color: transparent;
    cursor: pointer;
    color: var(--color-dark);
    position: absolute;
    left: 1rem;
  }

  .right .top button span {
    font-size: 2rem;
  }
}

.primary {
  color: var(--color-primary);
}

.danger {
  color: var(--color-danger);
}

.success {
  color: var(--color-success);
}

.orange {
  color: var(--color-orange);
}

.warning {
  color: var(--color-warning);
}

.light {
  color: var(--color-light);
}

.btn-small-square {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 7px;
  color: var(--color-white);
  width: 4rem;
  height: 2.8rem;
  cursor: pointer;
  transition: var(--transition-ease-out);
  -webkit-transition: var(--transition-ease-out);
}
.btn-small-square-success {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-success);
  border: 1px solid var(--color-success);
  border-radius: 7px;
  color: var(--color-white);
  width: 4rem;
  height: 2.8rem;
  cursor: pointer;
  transition: var(--transition-ease-out);
  -webkit-transition: var(--transition-ease-out);
}
.btn-small-square-danger {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-danger);
  border: 1px solid var(--color-danger);
  border-radius: 7px;
  color: var(--color-white);
  width: 4rem;
  height: 2.8rem;
  cursor: pointer;
  transition: var(--transition-ease-out);
  -webkit-transition: var(--transition-ease-out);
}
.btn-small-square-orange {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-orange);
  border: 1px solid var(--color-orange);
  border-radius: 7px;
  color: var(--color-white);
  width: 4rem;
  height: 2.8rem;
  cursor: pointer;
  transition: var(--transition-ease-out);
  -webkit-transition: var(--transition-ease-out);
}

.btn-search {
  background-color: transparent;
  font-size: 1rem;
  border: 1px solid var(--color-primary);
  border-radius: 7px;
  padding: 0.8rem 2rem;
  text-align: center;
  color: var(--color-primary);
  width: 15rem;
  cursor: pointer;
  font-weight: 550;
  transition: var(--transition-ease-out);
  -webkit-transition: var(--transition-ease-out);
}

.btn-dowload {
  background-color: transparent;
  font-size: 1rem;
  border: 1px solid var(--color-primary);
  border-radius: 7px;
  padding: 2rem;
  text-align: center;
  color: var(--color-primary);
  width: 15rem;
  cursor: pointer;
  font-weight: 550;
  transition: var(--transition-ease-out);
  -webkit-transition: var(--transition-ease-out);
}

:is(.btn-search):hover {
  background-color: var(--color-light);
}

.btn-search-danger {
  color: var(--color-white);
  border: 1px solid var(--color-danger);
  background-color: var(--color-danger);
}

.btn-search-danger:hover {
  background-color: transparent;
  color: var(--color-danger);
}
.btn-search-success {
  color: var(--color-white);
  border: 1px solid var(--color-success);
  background-color: var(--color-success);
}

.btn-search-success:hover {
  background-color: transparent;
  color: var(--color-success);
}

.btn-search-orange {
  color: var(--color-white);
  border: 1px solid var(--color-orange);
  background-color: var(--color-orange);
}

.btn-search-orange:hover {
  background-color: transparent;
  color: var(--color-orange);
}

.btn-search-primary {
  color: var(--color-white);
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
}

.btn-search-primary:hover {
  background-color: transparent;
  color: var(--color-primary);
}

.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: var(--transition-ease-out);
  -webkit-transition: var(--transition-ease-out);
}

.btn-icon i {
  font-size: 1.2rem;
}

.icon-success {
  color: var(--color-success);
  border: var(--border-success);
}

.icon-success-hover {
  color: var(--color-success);
  border: var(--border-success);
}

.icon-success-hover:hover {
  cursor: pointer;
  color: var(--color-white);
  background-color: var(--color-success);
}

.icon-danger {
  color: var(--color-danger);
  border: var(--border-danger);
}

.icon-warning {
  color: var(--color-warning);
  border: var(--border-warning);
}

.icon-primary {
  color: var(--color-primary);
  border: var(--border-primary);
}

.icon-primary:hover {
  cursor: pointer;
  color: var(--color-white);
  background-color: var(--color-primary);
}

.pagination {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: center;
}

.btn-square {
  width: 3rem;
  height: 3rem;
  border-radius: 7px;
  font-size: larger;
  cursor: pointer;
}

.icon-amine:hover {
  cursor: pointer;
  position: relative;
  bottom: 1px;
  transition: var(--transition);
  -webkit-transition: var(-- transition);
}

.btn-file {
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  padding: 0.8rem 1rem;
  border-radius: 7px;
  font-size: 0.8rem;
  font-weight: 500;
  transition: var(--transition);
  -webkit-transition: var(--transition);
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: inherit;
}

.btn-file-primary{
  color: var(--color-white);
  background-color: var(--color-primary);
}

.btn-file-primary:hover {
  background-color: transparent;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.btn-file-success{
  color: var(--color-white);
  background-color: var(--color-success);
}

.btn-file-success:hover {
  background-color: transparent;
  color: var(--color-success);
  border: 1px solid var(--color-success);
}

.btn-file-danger{
  color: var(--color-white);
  background-color: var(--color-danger);
}

.btn-file-danger:hover {
  background-color: transparent;
  color: var(--color-danger);
  border: 1px solid var(--color-danger);
}

.uppercase{
  text-transform: uppercase;
}

.cusor-pointer {
  cursor: pointer;
}

.card-message {
  background-color: var(--color-light);
  width: 100%;
  border-radius: var(--card-border-radius);
  padding: 1rem 2rem ;
}

.card-message h2 {
  font-size: 1.5rem;
  text-transform: uppercase;
}

.card-message small {
  font-size: 1.1rem;
}