/* ---------------- ROOT VARIABLES ------------- */

:root {
    --color-background: #eee;
    --color-primary: #1C2E82;
    --color-gray: #888;
    --color-white: #fff;
    --color-black: #000;
    --color-light: rgba(132, 139, 200, 0.18);
    --color-danger: red;
    --color-danger-hover: rgb(206, 3, 3);
    --color-success: green;
    --color-success-hover: rgb(2, 99, 2);
    --color-orange: #F77F00;
    --color-warning: #ffbb55;

    --border-raduis: 7px;
    --card-border-radius: 2rem;
    --border-radius-1: 0.4rem;
    --border-radius-2: 0.8rem;
    --border-radius-2: 1.2rem;
    --card-padding: 1.8rem;
    --padding-1: 1.2rem;
    
    --border: 1px solid var(--color-gray);
    --border-primary: 1px solid var(--color-primary);
    --border-black: 1px solid var(--color-black);
    --border-success: 1px solid var(--color-success);
    --border-danger: 1px solid var(--color-danger);
    --border-warning: 1px solid var(--color-warning);
    --border-dashed: 1px dashed var(--color-primary);
    --border-dashed-black: 1px dashed var(--color-black);
    --box-shadow: 0 2rem 3rem var(--color-light);
    --transition: all 300ms ease;
    --transition-ease-out: all 0.3s ease-out;
}


/* ---------------- DARK THEME VARIABLES ------------- */
.dark-theme-variables {
    --color-primary: #1C2E82;
    --color-background: #181e1a;
    --color-white: #202528;
    --color-dark: #edeffd;
    --color-gray: #888;
    --color-light: rgba(0, 0, 0, 0.4);
  
    --box-shadow: 0 2rem 3rem var(--color-light);
  }